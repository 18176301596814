<template>
  <section
    class="overflow-hidden px-20 max-md:px-5"
    style="background-color: #fcf8f0; height: 634px"
  >
    <div class="flex gap-5 h-full max-md:flex-col">
      <div class="flex flex-col w-[27%] max-md:ml-0 max-md:w-full">
        <div
          class="flex flex-col items-start self-stretch justify-center h-full w-full text-center"
        >
          <h1
            class="text-black tracking-[3.3px]"
            style="
              font-family: 'Brandon Grotesque';
              font-size: 22px;
              font-weight: 400;
              line-height: 61.695px;
            "
          >
            GIVE THE GIFT OF
          </h1>
          <p
            class="font-serif font-medium"
            style="color: #0e1e38; font-size: 70px; line-height: 72px"
          >
            Creativity
          </p>
          <a
            href="/gifts"
            class="gap-2.5 self-stretch px-12 py-5 mt-12 text-base text-white uppercase tracking-[2.4px] max-md:px-5 max-md:mt-10"
            style="background-color: #6d1623"
          >
            SHOP GIFT VOUCHERS
          </a>
        </div>
      </div>
      <div class="flex flex-col ml-5 w-[73%] max-md:ml-0 max-md:w-full">
        <!-- <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b92b9edbdd15efd957b2516085dc22d89669db3b0d59eabc76f1094a3dd44306?placeholderIfAbsent=true&apiKey=c666ad20f97e4d32840edeba9e04c0c2"
          alt="Gift of Creativity"
          class="object-contain w-full h-full"
        /> -->
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'GiftOfCreativity'
  }
</script>

<style scoped>
  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(2deg);
    }
    75% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .builder-04a570ce40a7734515906cbedb409a6c:hover {
    animation: wiggle 0.5s ease-in-out infinite;
    cursor: pointer;
  }
</style>

<template>
  <div style="background-color: #fcf8f0" class="min-h-screen">
    <MetaTags :metaTags="metaTags" />

    <GiftCard />

    <HeroAnimation />
  </div>
</template>

<script>
  import { metaTags } from '@/mixins/metaTags'
  import MetaTags from '@/Components/Shared/MetaTags'
  import HeroAnimation from '@/Components/Shared/HeroAnimation'
  import GiftCard from '@/Components/Shared/Home/GiftCard'

  export default {
    components: {
      MetaTags,
      HeroAnimation,
      GiftCard
    },

    mixins: [metaTags],

    props: {
      metaTags: {
        type: Object,
        default: () => ({
          title: 'Animated Hero | Create Academy',
          description: 'Experience our animated hero section'
        })
      }
    },

    computed: {
      currentPage() {
        return 'Animated Hero'
      }
    }
  }
</script>

import Layout from '@/Layouts/Layout'
import { App, plugin } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress'
import Vue from 'vue'
import VModal from 'vue-js-modal'
import I18n from './i18n.js.erb'
import Routes from './routes.js'
import Appsignal from '@appsignal/javascript'
import { errorHandler } from '@appsignal/vue'

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'stylesheets/application'
import 'vendor/lazysizes.min.js'

const environment = process.env.RAILS_ENV || process.env.NODE_ENV || 'development'

const appsignalKeys = {
  development: '7624fc8e-5260-4f0d-b9b4-99c5324db5ad',
  staging: '0b69f09c-2a8d-4426-bd6f-51eb9e65eca8',
  production: 'f3b7880f-f24d-4eba-8f0f-aacf01ace030'
}

const appsignal = new Appsignal({
  key: appsignalKeys[environment] || appsignalKeys.development
})

Vue.config.errorHandler = errorHandler(appsignal, Vue)

// check if the XSRF-TOKEN cookie isn't set and if a reload has happened to get the cookie
// the JSON.parse forces the value to be a boolean
if (
  document.cookie.indexOf('XSRF-TOKEN') < 0 &&
  !JSON.parse(localStorage?.getItem('xsrf-reload'))
) {
  // prevents endless loops with 404 responses
  localStorage?.setItem('xsrf-reload', true)

  // browser lost the authenticity token (issue related to safari-mobile)
  location.reload()
} else {
  localStorage?.setItem('xsrf-reload', false)
  Vue.use(plugin)
  InertiaProgress.init()

  // Modals
  Vue.use(VModal, {
    dynamic: true,
    dynamicDefaults: {
      window: '100%',
      height: 'auto',
      scrollable: true,
      adaptive: true
    }
  })

  // Routes
  Vue.prototype.$routes = Routes

  // Translations
  I18n.locale = 'en'
  Vue.prototype.$I18n = I18n

  const el = document.getElementById('app')

  // Pages that are regular Rails templates don't have any Vue component to
  // be mounted (and the #app element doesn't exist).
  // Without this check, a console error is shown in those situations.
  if (el) {
    new Vue({
      render: h =>
        h(App, {
          props: {
            initialPage: JSON.parse(el.dataset.page),
            resolveComponent: name => {
              // Add AnimatedHero to the dynamic imports
              if (name === 'Static/AnimatedHero') {
                return import('@/Pages/Static/AnimatedHero').then(module => {
                  const page = module.default
                  page.layout = page.layout === undefined ? Layout : page.layout
                  return page
                })
              }
              const page = require(`@/Pages/${name}`).default
              page.layout = page.layout === undefined ? Layout : page.layout
              return page
            }
          }
        })
    }).$mount(el)
  }
}

<template>
  <div>
    <!-- Existing Hero Content -->
    <div class="flex flex-col md:flex-row bg-white">
      <!-- Left Section - full width on mobile -->
      <div class="w-full md:w-5/12 relative" style="background-color: #e8ebf0">
        <div class="relative z-10 p-6 md:p-12 h-full flex flex-col justify-center">
          <h1 class="font-serif text-2xl lg:text-4xl text-blue-800 font-medium mb-8">
            Unlock Your Creativity
            <br />
            And All Courses
          </h1>
          <div class="w-full max-w-3xl">
            <div
              v-for="course in courses"
              :key="course.id"
              class="flex items-start"
              style="margin-bottom: 32px"
            >
              <div class="flex-none" style="margin-right: 24px">
                <svg
                  class="w-4 h-4 mt-2 text-gray-400"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div>
                <h2 class="text-2xl font-serif text-blue-800" style="margin-bottom: 4px">
                  {{ course.title }}
                </h2>
                <p
                  class="text-black text-base font-['Brandon_Grotesque'] font-[390] leading-normal"
                >
                  {{ course.description }}
                </p>
              </div>
            </div>
          </div>
          <button
            class="mt-2 bg-red-500 hover:bg-red-800 text-white inline-flex justify-center items-center px-16 py-5"
            style="
              min-width: 300px;
              max-width: 373px;
              gap: 10px;
              line-height: normal;
              letter-spacing: 2.4px;
            "
          >
            START LEARNING
          </button>
        </div>
      </div>

      <!-- Right Section - full width on mobile -->
      <div class="w-full md:w-7/12 relative overflow-hidden bg-white h-[642px]">
        <!-- Left Fade effect -->
        <div
          class="absolute pointer-events-none z-20 hidden md:block"
          aria-hidden="true"
          :style="{
            width: '23rem',
            background: `linear-gradient(to right, 
              rgba(232, 235, 240, 0) 0%, 
              rgba(232, 235, 240, 1) 0%,
              rgba(255, 255, 255, 0) 100%
            )`,
            left: '-12rem',
            top: 0,
            bottom: 0
          }"
        />

        <!-- Right Fade effect (mirrored) -->
        <div
          class="absolute pointer-events-none z-20 hidden md:block"
          aria-hidden="true"
          :style="{
            width: '23rem',
            background: `linear-gradient(to left, 
              rgba(232, 235, 240, 0) 0%, 
              rgba(232, 235, 240, 1) 0%,
              rgba(255, 255, 255, 0) 100%
            )`,
            right: '-12rem',
            top: 0,
            bottom: 0
          }"
        />

        <!-- Rest of the right section content -->
        <div class="flex flex-col h-full" style="gap: 3px">
          <!-- Top Row -->
          <div class="overflow-hidden hidden md:block" style="height: 384px">
            <div class="infinite-scroll-container top-row h-full" :style="{ '--duration': '80s' }">
              <div class="flex h-full" style="gap: 3px">
                <template v-for="(item, index) in duplicatedImages.top">
                  <div
                    v-if="item.type === 'split'"
                    :key="`top-split-${index}`"
                    class="flex-none w-80 h-96 relative flex flex-col gap-2"
                  >
                    <div class="flex-1 relative">
                      <img
                        :src="getImageUrl(item.upper)"
                        :alt="`Inspiration image ${index + 1} top`"
                        class="object-cover absolute inset-0 w-full h-full"
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                    <div class="flex-1 relative">
                      <img
                        :src="getImageUrl(item.lower)"
                        :alt="`Inspiration image ${index + 1} bottom`"
                        class="object-cover absolute inset-0 w-full h-full"
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  </div>
                  <div v-else :key="`top-${index}`" class="flex-none w-80 h-96 relative">
                    <img
                      :src="getImageUrl(item.src)"
                      :alt="`Inspiration image ${index + 1}`"
                      class="object-cover absolute inset-0 w-full h-full"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                </template>
              </div>
              <!-- Duplicate for seamless loop -->
              <div class="flex h-full" style="gap: 3px">
                <template v-for="(item, index) in duplicatedImages.top">
                  <div
                    v-if="item.type === 'split'"
                    :key="`top-split-${index}`"
                    class="flex-none w-80 h-96 relative flex flex-col gap-2"
                  >
                    <div class="flex-1 relative">
                      <img
                        :src="getImageUrl(item.upper)"
                        :alt="`Inspiration image ${index + 1} top`"
                        class="object-cover absolute inset-0 w-full h-full"
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                    <div class="flex-1 relative">
                      <img
                        :src="getImageUrl(item.lower)"
                        :alt="`Inspiration image ${index + 1} bottom`"
                        class="object-cover absolute inset-0 w-full h-full"
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  </div>
                  <div v-else :key="`top-${index}`" class="flex-none w-80 h-96 relative">
                    <img
                      :src="getImageUrl(item.src)"
                      :alt="`Inspiration image ${index + 1}`"
                      class="object-cover absolute inset-0 w-full h-full"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>

          <!-- Bottom Row -->
          <div class="overflow-hidden" style="height: 255px">
            <div
              class="infinite-scroll-container bottom-row h-full"
              :style="{ '--duration': '80s' }"
            >
              <div class="flex h-full" style="gap: 3px">
                <div
                  v-for="(image, index) in duplicatedImages.bottom"
                  :key="`bottom-${index}`"
                  :class="[
                    'flex-none h-60 relative',
                    image.width === 'double-width' ? '' : image.width
                  ]"
                  :style="image.width === 'double-width' ? 'width: 36rem' : ''"
                >
                  <img
                    :src="getImageUrl(image.src)"
                    :alt="`Inspiration image ${index + 7}`"
                    class="object-cover absolute inset-0 w-full h-full"
                    loading="lazy"
                    decoding="async"
                  />
                </div>
              </div>
              <!-- Duplicate for seamless loop -->
              <div class="flex h-full" style="gap: 3px">
                <div
                  v-for="(image, index) in duplicatedImages.bottom"
                  :key="`bottom-duplicate-${index}`"
                  :class="[
                    'flex-none h-60 relative',
                    image.width === 'double-width' ? '' : image.width
                  ]"
                  :style="image.width === 'double-width' ? 'width: 36rem' : ''"
                >
                  <img
                    :src="getImageUrl(image.src)"
                    :alt="`Inspiration image ${index + 7}`"
                    class="object-cover absolute inset-0 w-full h-full"
                    loading="lazy"
                    decoding="async"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeroAnimation',

    data() {
      return {
        courses: [
          {
            id: 'USP1',
            title: 'Expert-Led Courses',
            description: 'Learn from world-renowned designers and creative professionals.'
          },
          {
            id: 'USP2',
            title: 'Flexible Learning',
            description: 'Access courses anytime, anywhere, and learn at your own pace.'
          },
          {
            id: 'USP3',
            title: 'Practical Skills',
            description: 'Get hands-on experience with real-world projects and techniques.'
          }
        ],
        images: {
          topFull: ['hero/full/1', 'hero/full/2', 'hero/full/3'],
          topSplit: {
            upper: ['hero/split/upper/1', 'hero/split/upper/2', 'hero/split/upper/3'],
            lower: ['hero/split/lower/1', 'hero/split/lower/2', 'hero/split/lower/3']
          },
          bottom: [
            { src: 'hero/bottom/1', width: 'w-80' },
            { src: 'hero/bottom/2', width: 'w-80' },
            { src: 'hero/bottom/3', width: 'w-80' },
            { src: 'hero/bottom/4', width: 'w-80' },
            { src: 'hero/bottom/5', width: 'w-80' },
            { src: 'hero/bottom/6', width: 'w-80' },
            { src: 'hero/bottom/7', width: 'w-80' },
            { src: 'hero/bottom/8', width: 'double-width' }
          ]
        },
        isVisible: false
      }
    },

    computed: {
      duplicatedImages() {
        const topRowImages = []
        const fullImagesCount = this.images.topFull.length

        // Just need one set of images for each row since CSS will handle the loop
        for (let i = 0; i < fullImagesCount * 2; i++) {
          if (i % 3 === 0) {
            const upperIndex = Math.floor(i / 3) % this.images.topSplit.upper.length
            const lowerIndex = Math.floor(i / 3) % this.images.topSplit.lower.length
            topRowImages.push({
              type: 'split',
              upper: this.images.topSplit.upper[upperIndex],
              lower: this.images.topSplit.lower[lowerIndex]
            })
          } else {
            const fullIndex = i % fullImagesCount
            topRowImages.push({
              type: 'full',
              src: this.images.topFull[fullIndex]
            })
          }
        }

        return {
          top: topRowImages,
          bottom: this.images.bottom
        }
      }
    },

    methods: {
      getImageUrl(filename) {
        try {
          return require(`@/assets/images/${filename}.webp`)
        } catch (e) {
          console.warn(`Image not found: ${filename}`, e)
          return ''
        }
      }
    },

    mounted() {
      // Create intersection observer
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            this.isVisible = entry.isIntersecting
          })
        },
        {
          rootMargin: '50px 0px', // Start loading slightly before visible
          threshold: 0.1
        }
      )

      // Start observing the component
      observer.observe(this.$el)

      // Cleanup
      this.$once('hook:beforeDestroy', () => {
        observer.disconnect()
      })
    }
  }
</script>

<style scoped>
  .w-80 {
    width: 18rem;
  }
  .h-96 {
    height: 24rem;
  }
  .h-60 {
    height: 16rem;
  }

  .min-h-screen {
    margin-top: 0;
    padding-top: 0;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(calc(-50% - 1.5px));
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-50% - 1.5px));
    }
  }

  /* Top row moves right */
  .infinite-scroll-container.top-row {
    display: inline-flex;
    animation: slideRight var(--duration) linear infinite;
    animation-play-state: v-bind(isVisible ? 'running': 'paused');
  }

  /* Bottom row moves left */
  .infinite-scroll-container.bottom-row {
    display: inline-flex;
    animation: slideLeft var(--duration) linear infinite;
    animation-play-state: v-bind(isVisible ? 'running': 'paused');
  }

  .infinite-scroll-container > div {
    display: flex;
    margin-right: 3px;
  }

  .infinite-scroll-container .flex {
    display: flex;
    gap: 0;
  }
</style>
